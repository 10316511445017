import { Instance } from 'hive-client-utils';

export interface INote {
  timeStamp: string;
  name: string;
  type: string;
  text?: string;
}

export interface IMeetingName {
  en: string;
  fr: string;
}

// TODO: Create UUID type
export type UUID = string;
export type PatronId = UUID;

export default interface IPatron extends Instance {
  properties: {
    clinicianChatId?: string;
    conversationId?: string;
    currentCareId: string;
    justification?: string;
    meetingRequestExpiry: string;
    meetingUrl: string;
    meetingName: string;
    meetingPin?: string;
    participantId?: string;
    groupMeetingName: string;
    groupMeetingPin?: string;
    surveyId: UUID;
    state: string;
    firstName: string;
    lastName: string;
    pausedMeetingName?: IMeetingName;
    pronoun?: string;
    canSkipPreflight?: boolean;
  };
}

export enum SatisfactionScore {
  Nothing,
  VerySad,
  Sad,
  Neither,
  Happy,
  VeryHappy,
}
