import { useEffect, useState } from 'react';
export function useAcsCallState(adapter) {
    const [callState, setCallState] = useState();
    useEffect(() => {
        if (!adapter) {
            return;
        }
        adapter.onStateChange(setCallState);
        return () => adapter.offStateChange(setCallState);
    }, [adapter]);
    return callState;
}
