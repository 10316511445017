/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from 'lodash';
import moment from 'moment';
import { raam } from 'raam-client-lib';

import { uploadImage } from '@services/BlobService';
import { getLanguage, SupportedLanguages } from '@services/i18nService';

import {
  IPatronIds,
  ISurveyData,
  PatronId,
  SatisfactionScore,
  UUID,
} from '@typings';

const boolToYesAndNo = (bool: boolean) => {
  return bool ? 'Yes' : 'No';
};

function generateSurveyObject(surveyData: ISurveyData) {
  const {
    currentAddress,
    isFirstTime,
    alcoholQ,
    opioidQ,
    homeAddress,
    familyDoctor,
    pregnantQ,
    stimulantsQ,
    otherSubstancesQ,
    mentalHealthQ,
    shmbRegion,
  } = surveyData;

  const survey = [
    {
      question: 'survey.question.firstTime',
      answer: _.isNil(isFirstTime) ? '' : boolToYesAndNo(isFirstTime),
    },
    ...(shmbRegion
      ? [
          {
            question: 'survey.question.shmbRegion',
            answer: shmbRegion,
          },
        ]
      : []),
    {
      question: 'survey.question.useAlcohol',
      answer: _.isNil(alcoholQ) ? '' : boolToYesAndNo(alcoholQ),
    },
    {
      question: 'survey.question.useOpioid',
      answer: _.isNil(opioidQ) ? '' : boolToYesAndNo(opioidQ),
    },
    {
      question: 'survey.question.stimulantsOrAmphetamines',
      answer: _.isNil(stimulantsQ) ? '' : boolToYesAndNo(stimulantsQ),
    },
    {
      question: 'survey.question.useOfOtherSubstances',
      answer: _.isNil(otherSubstancesQ) ? '' : boolToYesAndNo(otherSubstancesQ),
    },
    {
      question: 'survey.question.mentalHealth',
      answer: _.isNil(mentalHealthQ) ? '' : boolToYesAndNo(mentalHealthQ),
    },
    {
      question: 'survey.question.doctorName',
      answer: _.isNil(familyDoctor) ? '' : familyDoctor.replace(/\n/gi, ' '),
    },
    {
      question: 'survey.question.physicalLocation',
      answer: !currentAddress ? '' : currentAddress.replace(/(\r\n|\n)/g, '|'),
    },
    {
      question: 'survey.question.homeAddress',
      answer: !homeAddress ? '' : homeAddress.replace(/(\r\n|\n)/g, '|'),
    },
    {
      question: 'survey.question.pregnant',
      answer: _.isNil(pregnantQ) ? '' : pregnantQ,
    },
  ];

  return survey;
}

function getCurrentLanguage() {
  const language = getLanguage();
  const sanitizedLanguage = _.includes(
    [SupportedLanguages.English, SupportedLanguages.French],
    language
  )
    ? language
    : SupportedLanguages.English;

  return sanitizedLanguage;
}

export function checkInPatron(
  surveyData: ISurveyData,
  initDateTime: string,
  denialType?: string
) {
  const {
    firstName,
    lastName,
    phoneNumber,
    allowSMS,
    email,
    isFirstTime = false,
    haveAppointment = false,
    pregnantQ = 'No',
    healthCardNumber = '',
    healthCardBlob,
    healthIdNumber = '',
    registrationNumber = '',
    dobDay,
    dobMonth,
    dobYear,
    userAgent = '',
    screenWidth = -1,
    screenHeight = -1,
    screenOrientation = '',
    selectedMeetingType = '',
    selectedMeetingName = '',
    pronoun = '',
    shmbRegion = '',
  } = surveyData;

  const patronData = {
    firstName,
    lastName,
    phoneNumber,
    email,
    isImportant: pregnantQ === 'Yes',
    isPhoneOnly: false,
    hasAppointment: haveAppointment,
    isWalkIn: false,
    isVirtual: true,
    isGroup: false,
    survey: generateSurveyObject(surveyData),
    allowSMS,
    firstTime: isFirstTime,
    healthCard: healthCardNumber,
    phidNumber: healthIdNumber,
    registrationNumber,
    dob: dobDay ? moment(`${dobYear}/${dobMonth}/${dobDay}`).toISOString() : '',
    notes: [],
    language: getCurrentLanguage(),
    denialType: denialType || '',
    deleted: !!denialType,
    userAgent,
    screenWidth,
    screenHeight,
    screenOrientation,
    requestedMeetingType: selectedMeetingType,
    requestedMeetingName: selectedMeetingName,
    pronoun,
  };

  return raam.patron
    .checkIn(patronData, initDateTime)
    .then((patronIds: IPatronIds) => {
      if (healthCardBlob) {
        const file = new File([healthCardBlob], 'Health Card');

        return raam.patron
          .prepareBlobUpload(patronIds.id)
          .then((url: string) => uploadImage(file, url))
          .then(() => patronIds);
      }

      return patronIds;
    });
}

export function getCurrentDateTime() {
  return raam.patron.getCurrentDateTime();
}

export function get(id: PatronId) {
  return raam.patron.get(id);
}

export function observe(patronData: IPatronIds) {
  return raam.patron.observe(patronData);
}

export function ackMeetingRequest(id: PatronId) {
  return raam.patron.states.ackMeetingRequest(id);
}

export function startMeeting(id: PatronId, careId: UUID) {
  return raam.patron.states.toInMeeting(id, careId);
}

export function setRating(id: PatronId, s: SatisfactionScore) {
  return raam.patron.setSatisfactionScore(id, s);
}

export function setSurvey(surveyId: UUID, s: SatisfactionScore) {
  return raam.patron.setSurvey(surveyId, s);
}

export function ackSurvey(patronId: PatronId) {
  return raam.patron.ackSurvey(patronId);
}

export function dismissChatNotification(patronId: PatronId) {
  return raam.patron.dismissChatNotification(patronId);
}

export function leaveQueue(id: PatronId, msg: string) {
  return raam.patron.states.eject(id, msg.replace(/\n/gi, ' '));
}

export function validatePin(
  meetingName: string,
  pin: string
): Promise<boolean> {
  return raam.patron
    .validatePin(meetingName, pin)
    .then(() => true)
    .catch(() => false);
}

const PatronService = {
  checkInPatron,
  get,
  observe,
  ackMeetingRequest,
  startMeeting,
  setRating,
  leaveQueue,
  validatePin,
};

export default PatronService;
