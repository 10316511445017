var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useMemo } from 'react';
import { onResolveDeepNoiseSuppressionDependencyLazy, onResolveVideoEffectDependencyLazy, useAzureCommunicationCallAdapter, } from '@azure/communication-react';
import { useAcsCredentials } from './useAcsCredentials';
import { useAcsRoomLocator } from './useAcsRoomLocator';
import { AcsCallAdapter } from '../Types';
// import abstract2 from '../assets/backgrounds/abstract2.jpg';
// import abstract3 from '../assets/backgrounds/abstract3.jpg';
// import room1 from '../assets/backgrounds/room1.jpg';
// import room2 from '../assets/backgrounds/room2.jpg';
// import room3 from '../assets/backgrounds/room3.jpg';
// import room4 from '../assets/backgrounds/room4.jpg';
// import likeEmoji from '../assets/reactions/likeEmoji.png';
// import heartEmoji from '../assets/reactions/heartEmoji.png';
// import laughEmoji from '../assets/reactions/laughEmoji.png';
// import clapEmoji from '../assets/reactions/clapEmoji.png';
// import surprisedEmoji from '../assets/reactions/surprisedEmoji.png';
import callEnded from '../assets/sounds/callEnded.mp3';
import callRinging from '../assets/sounds/callRinging.mp3';
import callBusy from '../assets/sounds/callBusy.mp3';
const VIDEO_BACKGROUND_IMAGES = [
// {
//   key: 'pastel',
//   url: abstract2,
//   tooltipText: 'Pastel Background',
// },
// {
//   key: 'rainbow',
//   url: abstract3,
//   tooltipText: 'Rainbow Background',
// },
// {
//   key: 'office',
//   url: room1,
//   tooltipText: 'Office Background',
// },
// {
//   key: 'plant',
//   url: room2,
//   tooltipText: 'Plant Background',
// },
// {
//   key: 'bedroom',
//   url: room3,
//   tooltipText: 'Bedroom Background',
// },
// {
//   key: 'livingroom',
//   url: room4,
//   tooltipText: 'Living Room Background',
// },
];
export function useAcsCallAdapter(acsRoomId, acsUserId, acsUserToken, displayName) {
    const credential = useAcsCredentials(acsUserToken);
    const locator = useAcsRoomLocator(acsRoomId);
    const adapterOptions = useMemo(() => ({
        deepNoiseSuppressionOptions: {
            onResolveDependency: onResolveDeepNoiseSuppressionDependencyLazy,
            deepNoiseSuppressionOnByDefault: true,
            hideDeepNoiseSuppressionButton: false,
        },
        videoBackgroundOptions: {
            videoBackgroundImages: VIDEO_BACKGROUND_IMAGES,
            onResolveDependency: onResolveVideoEffectDependencyLazy,
        },
        callingSounds: {
            callEnded: { url: callEnded },
            callRinging: { url: callRinging },
            callBusy: { url: callBusy },
        },
        // reactionResources: {
        //   likeReaction: {
        //     url: likeEmoji,
        //     frameCount: 102,
        //   },
        //   heartReaction: {
        //     url: heartEmoji,
        //     frameCount: 102,
        //   },
        //   laughReaction: {
        //     url: laughEmoji,
        //     frameCount: 102,
        //   },
        //   applauseReaction: {
        //     url: clapEmoji,
        //     frameCount: 102,
        //   },
        //   surprisedReaction: {
        //     url: surprisedEmoji,
        //     frameCount: 102,
        //   },
        // },
    }), []);
    const adapterArgs = useMemo(() => ({
        userId: { communicationUserId: acsUserId || '' },
        displayName,
        credential,
        locator,
        options: adapterOptions,
    }), [adapterOptions, credential, locator, acsUserId, displayName]);
    const afterCreate = useCallback((adapter) => __awaiter(this, void 0, void 0, function* () { return new AcsCallAdapter(adapter); }), []);
    const beforeDispose = useCallback((adapter) => __awaiter(this, void 0, void 0, function* () {
        yield adapter.leaveCall().catch(console.error);
    }), []);
    return useAzureCommunicationCallAdapter(adapterArgs, afterCreate, beforeDispose);
}
