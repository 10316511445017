import { useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import { AcsMeetingCall, MeetingUserType, useAcsMeeting } from 'raam-shared';
import { v4 as uuidv4 } from 'uuid';

import Connecting from '@screens/Meeting/Connecting';

import { joinAsGuest } from '@services/LinkService';

function LinkMeeting({
  meetingName,
  firstName,
  lastName,
  onLeaving,
  meetingPin,
}: {
  meetingName: string;
  firstName: string;
  lastName: string;
  meetingPin: string;
  onLeaving: (...a: any) => void;
  allowGroupMeeting: boolean;
}) {
  useEffect(() => {
    joinAsGuest(meetingName, `${firstName} ${lastName}`);
  }, [firstName, lastName, meetingName]);

  const [userOrAccountId] = useState<string>(uuidv4);
  const meeting = useAcsMeeting({
    meetingName,
    meetingPin,
    userOrAccountId,
    userType: MeetingUserType.PATRON,
  });

  return (
    <Box className="meeting-panel">
      {meeting ? (
        <AcsMeetingCall
          meeting={meeting}
          onRoomLeft={() => onLeaving(true)}
          userType={MeetingUserType.PATRON}
        />
      ) : (
        <Connecting />
      )}
    </Box>
  );
}

export default LinkMeeting;
