// Since we are using the CallComposite, we do not have access to the
// CallClient nor CallAgent. Since we need to call to use some features,
// we need to wrap the call adapter so that joinCall/startCall can save
// the call.
export class AcsCallAdapter {
    constructor(adapter) {
        this.adapter = adapter;
    }
    getCall() {
        return this.call;
    }
    joinCall(options) {
        this.call = this.adapter.joinCall(options);
        return this.call;
    }
    startCall(participants, options) {
        this.call = this.adapter.startCall(participants, options);
        return this.call;
    }
    onStateChange(handler) {
        this.adapter.onStateChange(handler);
    }
    offStateChange(handler) {
        this.adapter.offStateChange(handler);
    }
    getState() {
        return this.adapter.getState();
    }
    dispose() {
        this.adapter.dispose();
        this.call = undefined;
    }
    leaveCall(forEveryone) {
        return this.adapter.leaveCall(forEveryone).finally(() => {
            this.call = undefined;
        });
    }
    startCamera(options) {
        return this.adapter.startCamera(options);
    }
    stopCamera() {
        return this.adapter.stopCamera();
    }
    mute() {
        return this.adapter.mute();
    }
    unmute() {
        return this.adapter.unmute();
    }
    startScreenShare() {
        return this.adapter.startScreenShare();
    }
    raiseHand() {
        return this.adapter.raiseHand();
    }
    lowerHand() {
        return this.adapter.lowerHand();
    }
    onReactionClick(reaction) {
        return this.adapter.onReactionClick(reaction);
    }
    stopScreenShare() {
        return this.adapter.stopScreenShare();
    }
    removeParticipant(participant) {
        return this.adapter.removeParticipant(participant);
    }
    createStreamView(remoteUserId, options) {
        return this.adapter.createStreamView(remoteUserId, options);
    }
    disposeStreamView(remoteUserId, options) {
        return this.adapter.disposeStreamView(remoteUserId, options);
    }
    disposeScreenShareStreamView(remoteUserId) {
        return this.adapter.disposeScreenShareStreamView(remoteUserId);
    }
    disposeRemoteVideoStreamView(remoteUserId) {
        return this.adapter.disposeRemoteVideoStreamView(remoteUserId);
    }
    disposeLocalVideoStreamView() {
        return this.adapter.disposeLocalVideoStreamView();
    }
    holdCall() {
        return this.adapter.holdCall();
    }
    resumeCall() {
        return this.adapter.resumeCall();
    }
    addParticipant(participant, options) {
        return this.adapter.addParticipant(participant, options);
    }
    sendDtmfTone(dtmfTone) {
        return this.adapter.sendDtmfTone(dtmfTone);
    }
    startCaptions(options) {
        return this.adapter.startCaptions(options);
    }
    setCaptionLanguage(language) {
        return this.adapter.setCaptionLanguage(language);
    }
    setSpokenLanguage(language) {
        return this.adapter.setSpokenLanguage(language);
    }
    stopCaptions(options) {
        return this.adapter.stopCaptions(options);
    }
    startVideoBackgroundEffect(videoBackgroundEffect) {
        return this.adapter.startVideoBackgroundEffect(videoBackgroundEffect);
    }
    stopVideoBackgroundEffects() {
        return this.adapter.stopVideoBackgroundEffects();
    }
    updateBackgroundPickerImages(backgroundImages) {
        return this.adapter.updateBackgroundPickerImages(backgroundImages);
    }
    updateSelectedVideoBackgroundEffect(selectedVideoBackground) {
        return this.adapter.updateSelectedVideoBackgroundEffect(selectedVideoBackground);
    }
    submitSurvey(survey) {
        return this.adapter.submitSurvey(survey);
    }
    startSpotlight(userIds) {
        return this.adapter.startSpotlight(userIds);
    }
    stopSpotlight(userIds) {
        return this.adapter.stopSpotlight(userIds);
    }
    stopAllSpotlight() {
        return this.adapter.stopAllSpotlight();
    }
    askDevicePermission(constrain) {
        return this.adapter.askDevicePermission(constrain);
    }
    queryCameras() {
        return this.adapter.queryCameras();
    }
    queryMicrophones() {
        return this.adapter.queryMicrophones();
    }
    querySpeakers() {
        return this.adapter.querySpeakers();
    }
    setCamera(sourceInfo, options) {
        return this.adapter.setCamera(sourceInfo, options);
    }
    setMicrophone(sourceInfo) {
        return this.adapter.setMicrophone(sourceInfo);
    }
    setSpeaker(sourceInfo) {
        return this.adapter.setSpeaker(sourceInfo);
    }
    on(event, listener) {
        return this.adapter.on(event, listener);
    }
    off(event, listener) {
        return this.adapter.off(event, listener);
    }
    startNoiseSuppressionEffect() {
        return this.adapter.startNoiseSuppressionEffect();
    }
    stopNoiseSuppressionEffect() {
        return this.adapter.stopNoiseSuppressionEffect();
    }
    muteParticipant(userId) {
        return this.adapter.muteParticipant(userId);
    }
    muteAllRemoteParticipants() {
        return this.adapter.muteAllRemoteParticipants();
    }
}
