import { useMemo } from 'react';
import { COMPOSITE_LOCALE_EN_US, COMPOSITE_LOCALE_FR_FR, } from '@azure/communication-react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
export function useAcsCallLocale(overrides) {
    const { i18n } = useTranslation();
    return useMemo(() => {
        const base = i18n.language === 'fr' ? COMPOSITE_LOCALE_FR_FR : COMPOSITE_LOCALE_EN_US;
        return _.merge(base, overrides || {});
    }, [i18n.language, overrides]);
}
