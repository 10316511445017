import { deepFreeze } from './utils/utils';
export const TOOLTIP_DELAY = 200;
const InQStyleGuide = deepFreeze({
    black: '#000000',
    green: '#06A933',
    grey: {
        dark: '#1E1E1E',
        medium: '#282828',
        light: '#585858',
        lightest: '#939393',
    },
    lime: '#A4D552',
    red: '#ff2d2d',
    white: '#ffffff',
    orange: '#FF7000',
});
export default InQStyleGuide;
// Used to store meeting settings in localStorage (other than the ones saved by Dyte)
export const BACKGROUND_BLUR_SETTINGS_KEY = 'DyteMeeting-blurSetting';
export const MIC_ON_SETTINGS_KEY = 'DyteMeeting-microphoneOnSetting';
export const CAMERA_ON_SETTINGS_KEY = 'DyteMeeting-cameraOnSetting';
export const VIDEO_INPUT_SETTINGS_KEY = 'DyteMeeting-videoInput';
export const AUDIO_INPUT_SETTINGS_KEY = 'DyteMeeting-audioInput';
export const AUDIO_OUTPUT_SETTINGS_KEY = 'DyteMeeting-audioOutput';
// Default values for above settings
export const DEFAULT_BACKGROUND_BLUR = false;
export const DEFAULT_MIC_ON = true;
export const DEFAULT_CAMERA_ON = true;
export const DEFAULT_DEVICE = 'default';
// These are used for speech-to-text (live captioning)
export const DEFAULT_CAPTION_LANGUAGE_CODE = 'en-CA';
export const STT_TOKEN_MAX_AGE_SEC = 540; // token is valid for 10 minutes however Microsoft recommends using for 9 minutes as a buffer
export const SPEECH_TOKEN_KEY = 'speechToken'; // sessionStorage key
// Participant tile border colours
export const DEFAULT_PARTICIPANT_COLOUR = InQStyleGuide.grey.lightest;
export const ACTIVE_SPEAKER_COLOUR = InQStyleGuide.lime;
export const RAISED_HAND_COLOUR = InQStyleGuide.orange;
export const PRESETS_CLINICIAN = [
    // Mult
    'raam_poc_host',
];
