import { jsx as _jsx } from "react/jsx-runtime";
import { useCallback, useEffect, useState } from 'react';
import { CallComposite } from '@azure/communication-react';
import { initializeIcons } from '@fluentui/react';
import { CircularProgress, Container as BaseContainer, styled, } from '@material-ui/core';
import clsx from 'clsx';
import { useAcsCallAdapterEventHandler, useAcsCallCompositeOptions, useAcsCallLocale, useAcsCallState, useAcsDebugLogger, useAcsFormFactor, useAcsRaiseHandFeature, useAcsTheme, useOnAcsMuteChanged, useOnAcsParticipantsJoined, useOnAcsParticipantsLeft, } from '../../hooks';
import { MeetingUserType } from '../MeetingCall/MeetingCall';
initializeIcons(undefined, { disableWarnings: true });
const CallContainer = styled(BaseContainer)(() => ({
    width: '100%',
    height: '100%',
    maxWidth: 'unset',
    // Make the inside padding and margin so the content takes as
    // much space as it can without overlapping the border
    padding: '2px',
    margin: 0,
    // Remove the padding from the first child of the video gallery element
    '& [data-ui-id="video-gallery"] > div:first-of-type': {
        padding: 0,
    },
}));
export function AcsMeetingCall({ meeting, userType, onRoomJoined, onRoomLeft, participantCallbacks, hideLeave, }) {
    const [participants, setParticipants] = useState([]);
    useEffect(() => {
        if (participantCallbacks === null || participantCallbacks === void 0 ? void 0 : participantCallbacks.onParticipantsChanged) {
            participantCallbacks.onParticipantsChanged(participants);
        }
    }, [participantCallbacks, participants]);
    useAcsCallAdapterEventHandler(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter, 'participantsJoined', useOnAcsParticipantsJoined({
        onJoined: participantCallbacks === null || participantCallbacks === void 0 ? void 0 : participantCallbacks.onParticipantJoined,
        onChanged: setParticipants,
    }));
    useAcsCallAdapterEventHandler(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter, 'participantsLeft', useOnAcsParticipantsLeft({
        onLeft: participantCallbacks === null || participantCallbacks === void 0 ? void 0 : participantCallbacks.onParticipantLeft,
        onChanged: setParticipants,
    }));
    useAcsCallAdapterEventHandler(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter, 'isMutedChanged', useOnAcsMuteChanged({
        onChanged: setParticipants,
    }));
    const onCallEnded = useCallback(() => {
        onRoomLeft === null || onRoomLeft === void 0 ? void 0 : onRoomLeft();
    }, [onRoomLeft]);
    useAcsCallAdapterEventHandler(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter, 'callEnded', onCallEnded);
    const onError = useCallback((error) => {
        console.error('DEBUG_M21 error', error);
    }, []);
    useAcsCallAdapterEventHandler(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter, 'error', onError);
    const fluentTheme = useAcsTheme();
    const formFactor = useAcsFormFactor();
    const callState = useAcsCallState(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter);
    const callOptions = useAcsCallCompositeOptions({ userType, hideLeave });
    const callLocale = useAcsCallLocale();
    useEffect(() => {
        if ((callState === null || callState === void 0 ? void 0 : callState.page) === 'call') {
            onRoomJoined === null || onRoomJoined === void 0 ? void 0 : onRoomJoined();
        }
    }, [callState === null || callState === void 0 ? void 0 : callState.page, onRoomJoined]);
    useEffect(() => {
        const disposeAdapter = () => { var _a; return (_a = meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter) === null || _a === void 0 ? void 0 : _a.dispose(); };
        window.addEventListener('beforeunload', disposeAdapter);
        return () => window.removeEventListener('beforeunload', disposeAdapter);
    }, [meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter]);
    useAcsRaiseHandFeature(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter, callState, setParticipants);
    useAcsDebugLogger(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter, callState);
    if (!(meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter)) {
        return _jsx(CircularProgress, {});
    }
    return (_jsx(CallContainer, Object.assign({ className: clsx(userType === MeetingUserType.CLINICIAN && 'clinician', userType === MeetingUserType.PATRON && 'patron', hideLeave && 'hide-leave') }, { children: _jsx(CallComposite, { fluentTheme: fluentTheme, adapter: meeting === null || meeting === void 0 ? void 0 : meeting.callAdapter, formFactor: formFactor, locale: callLocale, options: callOptions }) })));
}
