import { useEffect } from 'react';
import { Features, } from '@azure/communication-calling';
import _ from 'lodash';
import { useAcsCall } from './useAcsCall';
const logNetworkDiagnostics = (v) => 
// eslint-disable-next-line no-console
console.log('DEBUG_M21 Network Diagnostics', v);
const logMediaDiagnostics = (v) => 
// eslint-disable-next-line no-console
console.log('DEBUG_M21 Network Diagnostics', v);
const logMediaStatsSample = _.debounce(
// eslint-disable-next-line no-console
(v) => console.log('DEBUG_M21 Media Stats Sample', v), 10000, { maxWait: 15000 });
const logMediaStatsSummary = (v) => 
// eslint-disable-next-line no-console
console.log('DEBUG_M21 Media Stats Summary', v);
const logOptimalVideoCount = _.debounce(
// eslint-disable-next-line no-console
(v) => console.log('DEBUG_M21 Optimal Video Count', v), 10000, { maxWait: 15000 });
export function useAcsDebugLogger(adapter, callState) {
    const call = useAcsCall(adapter, callState);
    useEffect(() => {
        const featureApi = call === null || call === void 0 ? void 0 : call.feature(Features.UserFacingDiagnostics);
        featureApi === null || featureApi === void 0 ? void 0 : featureApi.network.on('diagnosticChanged', logNetworkDiagnostics);
        featureApi === null || featureApi === void 0 ? void 0 : featureApi.media.on('diagnosticChanged', logMediaDiagnostics);
        return () => {
            featureApi === null || featureApi === void 0 ? void 0 : featureApi.network.off('diagnosticChanged', logNetworkDiagnostics);
            featureApi === null || featureApi === void 0 ? void 0 : featureApi.media.off('diagnosticChanged', logMediaDiagnostics);
        };
    }, [call]);
    useEffect(() => {
        const featureApi = call === null || call === void 0 ? void 0 : call.feature(Features.MediaStats);
        const collector = featureApi === null || featureApi === void 0 ? void 0 : featureApi.createCollector({
            // This does not seem to work, hence the debouncing
            aggregationInterval: 15,
            dataPointsPerAggregation: 5,
        });
        collector === null || collector === void 0 ? void 0 : collector.on('sampleReported', logMediaStatsSample);
        collector === null || collector === void 0 ? void 0 : collector.on('summaryReported', logMediaStatsSummary);
        return () => {
            collector === null || collector === void 0 ? void 0 : collector.off('sampleReported', logMediaStatsSample);
            collector === null || collector === void 0 ? void 0 : collector.off('summaryReported', logMediaStatsSummary);
            collector === null || collector === void 0 ? void 0 : collector.dispose();
        };
    }, [call]);
    useEffect(() => {
        const featureApi = call === null || call === void 0 ? void 0 : call.feature(Features.OptimalVideoCount);
        const listener = () => logOptimalVideoCount((featureApi === null || featureApi === void 0 ? void 0 : featureApi.optimalVideoCount) || 0);
        featureApi === null || featureApi === void 0 ? void 0 : featureApi.on('optimalVideoCountChanged', listener);
        return () => {
            featureApi === null || featureApi === void 0 ? void 0 : featureApi.off('optimalVideoCountChanged', listener);
        };
    }, [call]);
}
