import { sendNotification } from '@dytesdk/react-ui-kit';
import { useCallback, useEffect } from 'react';
import { useDyteMeeting } from '@dytesdk/react-web-core';
import { useLocale } from './useLocale';
import { logger } from '../utils';
import { useRoomConfig } from './useRoomConfig';
// Broadcasted event types
export var BroadcastEventType;
(function (BroadcastEventType) {
    BroadcastEventType["HAND_RAISE"] = "hand-raise";
    BroadcastEventType["LIVE_CAPTION"] = "live-caption";
})(BroadcastEventType || (BroadcastEventType = {}));
export function useBroadcastedEvents() {
    const { meeting } = useDyteMeeting();
    const languagePack = useLocale();
    const { roomConfig } = useRoomConfig(meeting.meta.meetingId, meeting.self.customParticipantId);
    // Handle the hand-raise event
    const handleHandRaise = useCallback((payload) => {
        if (!payload.name) {
            return;
        }
        const notificationObj = {
            id: new Date().getTime().toString(),
            message: `${languagePack('broadcast_raise_hand').replace('{{name}}', payload.name.toString())}`,
            duration: 3000,
        };
        sendNotification(notificationObj, 'message');
    }, [languagePack]);
    // Handle the live-caption event
    const handleLiveCaption = useCallback((payload) => {
        if (roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.isCaptioningEnabled) {
            // Temporary: log caption TODO: enqueue caption for UI display
            logger(payload.caption, ` ${payload.name} speaking:`, {
                level: 'info',
            });
        }
    }, [roomConfig === null || roomConfig === void 0 ? void 0 : roomConfig.isCaptioningEnabled]);
    useEffect(() => {
        if (!meeting) {
            return;
        }
        meeting.participants.on('broadcastedMessage', ({ payload, type }) => {
            if (!payload) {
                return;
            }
            switch (type) {
                case BroadcastEventType.HAND_RAISE:
                    handleHandRaise(payload);
                    break;
                case BroadcastEventType.LIVE_CAPTION:
                    handleLiveCaption(payload);
                    break;
                default:
                    logger(type, 'Unknown broadcast event type', { level: 'warn' });
            }
        });
        return () => {
            meeting.participants.removeAllListeners('broadcastedMessage');
        };
    }, [handleHandRaise, handleLiveCaption, meeting]);
}
