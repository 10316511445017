import { useEffect } from 'react';
export function useAcsCallAdapterEventHandler(adapter, eventName, callback) {
    useEffect(() => {
        if (!adapter) {
            return;
        }
        const listener = (event) => callback(event, eventName, adapter);
        adapter.on(eventName, listener);
        return () => {
            adapter.off(eventName, listener);
        };
    }, [adapter, callback, eventName]);
}
