import {
  createContext,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AcsMeeting, MeetingUserType, useAcsMeeting } from 'raam-shared';

import { usePatronDisplayName } from '@hooks/usePatronDisplayName';

import IPatron from '@typings/IPatron';

export enum MeetingViewEnum {
  InMeeting = 'IN_MEETING',
  InRejoin = 'IN_REJOIN',
}

const getDefaultDataContext = () => ({
  meeting: undefined,
  meetingViewState: MeetingViewEnum.InMeeting,
  setMeetingViewState: () => {},
});

export interface IMeetingContext {
  meeting: AcsMeeting | undefined;
  meetingViewState: MeetingViewEnum;
  setMeetingViewState: (state: MeetingViewEnum) => void;
}

const MeetingContext = createContext<IMeetingContext>(getDefaultDataContext());

export default MeetingContext;

export function MeetingContextProvider({
  children,
  patron,
}: PropsWithChildren<{ patron: IPatron }>): JSX.Element {
  const [meetingViewState, setMeetingViewState] = useState<MeetingViewEnum>(
    MeetingViewEnum.InMeeting
  );

  const { meetingName, meetingPin } = useMemo(() => {
    if (meetingViewState !== MeetingViewEnum.InMeeting) {
      return {};
    }

    switch (patron?.properties?.state) {
      case 'IN_MEETING':
      case 'IN_BREAKOUT':
        return {
          meetingName: patron.properties.meetingName,
          meetingPin: patron.properties.meetingPin,
        };

      case 'IN_GROUP_MEETING':
        return {
          meetingName: patron.properties.groupMeetingName,
          meetingPin: patron.properties.groupMeetingPin,
        };

      default:
        return {};
    }
  }, [
    meetingViewState,
    patron.properties.groupMeetingName,
    patron.properties.groupMeetingPin,
    patron.properties.meetingName,
    patron.properties.meetingPin,
    patron.properties?.state,
  ]);

  const prevPatronStateRef = useRef<string>('');
  useEffect(() => {
    if (prevPatronStateRef.current !== patron?.properties?.state) {
      setMeetingViewState((state) =>
        state === MeetingViewEnum.InMeeting ? state : MeetingViewEnum.InMeeting
      );
    }

    if (patron?.properties?.state) {
      prevPatronStateRef.current = patron?.properties?.state;
    }
  }, [patron?.properties?.state]);

  const displayName = usePatronDisplayName(patron);
  const meeting = useAcsMeeting({
    meetingName,
    meetingPin,
    displayName,
    userType: MeetingUserType.PATRON,
    userOrAccountId: patron?.id,
  });

  const value = useMemo(
    () => ({
      meeting,
      meetingViewState,
      setMeetingViewState,
    }),
    [meeting, meetingViewState]
  );

  return (
    <MeetingContext.Provider value={value}>{children}</MeetingContext.Provider>
  );
}
