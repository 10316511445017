import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { MeetingUserType, ParticipantsViews, useDeviceOrientation } from '..';
import { useMeetingStore } from '../../context';
import GalleryView from '../ParticipantViews/GalleryView';
import GridView from '../ParticipantViews/GridView';
import MainParticipantsView from '../ParticipantViews/MainParticipantsView';
import { useIsMobile } from '../../hooks';
const useStyles = makeStyles((theme) => ({
    tilesContainer: {
        display: 'flex',
        flex: 1,
        flexGrow: 1,
        border: '3px solid transparent',
        marginBottom: theme.spacing(9.5),
    },
    mobile: {
        marginBottom: 0,
    },
}));
export function MeetingView({ userType }) {
    const classes = useStyles();
    const { isMobile, participantsView } = useMeetingStore();
    const { isPortrait } = useDeviceOrientation();
    const isMobileDevice = useIsMobile();
    return (_jsxs(Box, Object.assign({ className: clsx(classes.tilesContainer, {
            [classes.mobile]: isMobileDevice,
        }) }, { children: [userType === MeetingUserType.PATRON && (_jsx(MainParticipantsView, { isPortrait: isPortrait, isMobile: isMobile })), userType === MeetingUserType.CLINICIAN &&
                participantsView === ParticipantsViews.GRID && _jsx(GridView, {}), userType === MeetingUserType.CLINICIAN &&
                participantsView === ParticipantsViews.SPEAKER && _jsx(GalleryView, {})] })));
}
