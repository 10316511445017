import { useCallback } from 'react';
import _ from 'lodash';
import { remoteParticipantToMeetingParticipant } from './useOnAcsParticipantsJoined';
export function useOnAcsParticipantsLeft({ onLeft, onChanged, }) {
    return useCallback(({ removed }) => {
        const removedParticipants = _.map(removed, remoteParticipantToMeetingParticipant);
        if (onLeft) {
            _.each(removedParticipants, onLeft);
        }
        onChanged === null || onChanged === void 0 ? void 0 : onChanged((previous) => {
            const ids = _.map(removedParticipants, 'id');
            return _.reject(previous, (p) => _.includes(ids, p.id));
        });
    }, [onChanged, onLeft]);
}
