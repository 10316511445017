import { useCallback } from 'react';
import _ from 'lodash';
export function remoteParticipantToMeetingParticipant(participant) {
    return {
        id: (participant.identifier.kind === 'communicationUser' &&
            participant.identifier.communicationUserId) ||
            '',
        isClinician: participant.role === 'Presenter',
        name: participant.displayName || '',
        audioEnabled: participant.isMuted,
        disableAudio: () => participant.mute(),
    };
}
export function useOnAcsParticipantsJoined({ onJoined, onChanged, }) {
    return useCallback(({ joined }) => {
        const newParticipants = _.map(joined, remoteParticipantToMeetingParticipant);
        onChanged === null || onChanged === void 0 ? void 0 : onChanged((previous) => _.concat(previous, newParticipants));
        if (onJoined) {
            _.each(newParticipants, onJoined);
        }
    }, [onChanged, onJoined]);
}
