import { useEffect, useMemo, useState } from 'react';
import { raam } from 'raam-client-lib';
import { MeetingUserType } from '../components';
import { useAcsCallAdapter } from './useAcsCallAdapter';
const getRoomToken = ({ groupCall, userType, displayName, userOrAccountId, meetingName, meetingPin, }) => {
    if (userType === MeetingUserType.PATRON) {
        return raam.patron.getRoomToken(displayName, userOrAccountId, meetingName, meetingPin || '');
    }
    if (groupCall) {
        return raam.clinician.getGroupHostRoomToken(displayName, userOrAccountId, meetingName);
    }
    return raam.clinician.getHostRoomToken(displayName, userOrAccountId, meetingName);
};
export function useAcsMeeting({ groupCall, userType, meetingName, meetingEnded, meetingPin, userOrAccountId, displayName, }) {
    const [meetingId, setMeetingId] = useState('');
    const [token, setToken] = useState('');
    const [participantId, setParticipantId] = useState('');
    useEffect(() => {
        if (!meetingName ||
            !userOrAccountId ||
            !displayName ||
            meetingEnded ||
            token) {
            return;
        }
        getRoomToken({
            groupCall,
            userType,
            displayName,
            userOrAccountId,
            meetingName,
            meetingPin,
        }).then((res) => {
            setToken(res.token);
            setParticipantId(res.participantId);
            setMeetingId(res.meetingId);
        });
    }, [
        displayName,
        groupCall,
        meetingName,
        meetingPin,
        meetingEnded,
        token,
        userOrAccountId,
        userType,
    ]);
    const callAdapter = useAcsCallAdapter(meetingId, participantId, token, displayName);
    return useMemo(() => callAdapter
        ? {
            callAdapter,
            leave: () => callAdapter.leaveCall(),
        }
        : undefined, [callAdapter]);
}
