import { useCallback } from 'react';
import _fp from 'lodash/fp';
const updateMute = (event) => _fp.flow([
    // clone to make sure the array of participants changes
    _fp.clone,
    _fp.map((mp) => {
        var _a, _b;
        if (((_a = event === null || event === void 0 ? void 0 : event.participantId) === null || _a === void 0 ? void 0 : _a.kind) !== 'communicationUser' ||
            mp.id !== ((_b = event.participantId) === null || _b === void 0 ? void 0 : _b.communicationUserId)) {
            return mp;
        }
        return Object.assign(Object.assign({}, mp), { audioEnabled: event.isMuted === false });
    }),
]);
export function useOnAcsMuteChanged({ onChanged }) {
    return useCallback((event) => {
        onChanged === null || onChanged === void 0 ? void 0 : onChanged(updateMute(event));
    }, [onChanged]);
}
