import { useMemo } from 'react';
import { AzureCommunicationTokenCredential } from '@azure/communication-common';
export function useAcsCredentials(acsToken) {
    return useMemo(() => {
        try {
            if (acsToken) {
                return new AzureCommunicationTokenCredential(acsToken);
            }
        }
        catch (e) {
            console.error(e);
        }
        return undefined;
    }, [acsToken]);
}
