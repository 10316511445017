import { useMemo } from 'react';
import { MeetingUserType } from '../components';
export function useAcsCallCompositeOptions({ userType, hideLeave, }) {
    return useMemo(() => ({
        galleryOptions: {
            layout: 'focusedContent',
        },
        surveyOptions: {
            disableSurvey: true,
        },
        callControls: {
            holdButton: false,
            reactionButton: false,
            raiseHandButton: userType === MeetingUserType.PATRON,
            peopleButton: userType === MeetingUserType.CLINICIAN,
            endCallButton: !hideLeave,
        },
        branding: {
            logo: {
                url: `${process.env.PUBLIC_URL}/logo512.png`,
            },
        },
    }), [hideLeave, userType]);
}
