import { useState, useEffect } from 'react';
export function useAcsCall(adapter, callState) {
    const [call, setCall] = useState();
    useEffect(() => {
        if ((callState === null || callState === void 0 ? void 0 : callState.page) !== 'call' || !adapter || !(adapter === null || adapter === void 0 ? void 0 : adapter.getCall())) {
            return setCall(undefined);
        }
        setCall(adapter.getCall());
    }, [adapter, callState === null || callState === void 0 ? void 0 : callState.page]);
    return call;
}
