/* eslint-disable no-console */
import { useEffect } from 'react';
import { Features } from '@azure/communication-calling';
import _fp from 'lodash/fp';
const updateHandRaised = (handRaised, event) => _fp.flow([
    // clone to make sure the array of participants changes
    _fp.clone,
    _fp.map((mp) => {
        const participantId = event.identifier
            .communicationUserId;
        if (mp.id !== participantId) {
            return mp;
        }
        return Object.assign(Object.assign({}, mp), { handRaised });
    }),
]);
export function useAcsRaiseHandFeature(adapter, callState, onChanged) {
    useEffect(() => {
        var _a;
        if ((callState === null || callState === void 0 ? void 0 : callState.page) !== 'call') {
            return;
        }
        if (!(adapter === null || adapter === void 0 ? void 0 : adapter.getCall())) {
            return;
        }
        const raiseHandsApi = (_a = adapter.getCall()) === null || _a === void 0 ? void 0 : _a.feature(Features.RaiseHand);
        if (!raiseHandsApi) {
            return;
        }
        const onRaiseHand = (event) => {
            onChanged === null || onChanged === void 0 ? void 0 : onChanged(updateHandRaised(true, event));
        };
        const onLoweredHand = (event) => {
            onChanged === null || onChanged === void 0 ? void 0 : onChanged(updateHandRaised(false, event));
        };
        raiseHandsApi.on('raisedHandEvent', onRaiseHand);
        raiseHandsApi.on('loweredHandEvent', onLoweredHand);
        return () => {
            raiseHandsApi.off('raisedHandEvent', onRaiseHand);
            raiseHandsApi.off('loweredHandEvent', onLoweredHand);
        };
    }, [adapter, callState, onChanged]);
}
